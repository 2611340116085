.phantom {
  display: block;
  padding: 100px;
  width: 100%;
}

.footerStyle {
  background-color: #f8f8f8;
  border-top: 1px solid #e7e7e7;
  text-align: center;
  padding: 20px;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
}

#compact {
  padding-right: 0;
  padding-left: 0;
  padding-bottom: 0;
  padding-top: 0;
}

html,
body,
pre,
code,
kbd,
samp,
div,
button,
.ui,
h2,
h1,
p,
h3,
h4,
h5, 
h6,
subtitle1,
body1,
span,
a,
label,
input {
  font-family: "Press Start 2P" !important;
}

.ui {
  font-family: "Press Start 2P" !important;
}

span.ka-icon.ka-icon-sort.ka-icon-sort-arrow-up, span.ka-icon.ka-icon-sort.ka-icon-sort-arrow-down {
  font-family: 'icons' !important;
}