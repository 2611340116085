.container {
    width: 100%;
    box-sizing: border-box; 
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50vh;
    font-size: 4em;
    background: #000;
    color: #f4a142;
  }
  
  .item {
    margin: 0 20px;
    border: 2px solid #f4a142;
    padding: 10px 20px;
    border-radius: 5px;
  }
  
  @media (max-width: 600px) {
    .container {
      font-size: 1em;
    }
    
    .item {
      margin: 0 10px;
      padding: 5px 10px;
    }
  }
  