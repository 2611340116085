/* ImageComponent.css */

@keyframes zoom {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(2);
    }
    100% {
      transform: scale(1);
    }
  }
  
  .imageAnimation {
    animation: zoom 60s infinite;
    position: relative; /* Add this line */
    z-index: -1; /* Add this line */
    width: 100%;
    height: auto;
    display: block;
  }
  